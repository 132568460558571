import Service from './index';

export default {

  userDetail() {
    return Service.get('/userAccount/userDetail');
  },
  // creting third part portal IDs
  createPortalID(data) {
    return Service.post("/portal/CreateClientPortal",data);
  },
  fetchPortalBalance(data) {
    return Service.post("/userAccount/fetchPortalBalance",data);
  },
  withdrawPortalBalance(data) {
    return Service.post("/userAccount/withdrawPortalBalance",data);
  },
  createPortalUser(data) {
    return Service.post("/userAccount/createPortalID", data);
  },
  
  validateTransactionCode(data,){
    return Service.get("/userAccount/validateTransactionCode?TransactionCode="+data)
  },

  changeTransactionCode(data){
    return Service.post("/userAccount/changeTransactionCode",data)
  },

  getTransactionCode(){
    return Service.get("/userAccount/getTransactionCode")
  },

  userChangePassword(data){
    return Service.post("/userAccount/userChangePassword", data);
  },

  userDepositTransfer(data) {
    return Service.post("/userAccount/depositTransfer", data);
  },

  userWithdrawlTransfer(data) {
    return Service.post("/userAccount/withdrawlTransfer", data);
  },
  
  checkClientNameAvailibilty(data) {
    return Service.post("/userAccount/checkUserAvailibility", data);
  },

  userSendOtp(data) {
    return Service.get('/userAccount/sendOtp/'+data);
  },

  userLoginOtp(data) {
    return Service.post('/userAccount/userLoginOtp',data);
  },
  userVerifyOtp(mobile, otp) {
    return Service.get('/userAccount/verfiyOtp/'+mobile+ "/"+otp);
  },
  userVerifyOtpLogin(mobile, otp) {

    // console.log('loginPhone',mobile)
    // console.log('otp',otp)
    return Service.get('/userAccount/verfiyOtpLogin/'+mobile+ "/"+otp);
  },
  webSettings() {
    return Service.get('/websiteSetting/getwebsiteSetting');
  },

  getBankDetails(SelectedAmount) {
    console.log('Checking values',SelectedAmount)
    return Service.get('/websiteSetting/getBankDetails/'+SelectedAmount);
  },

  getUPIDetails(SelectedAmount) {
    return Service.get("/websiteSetting/getUPIDetails/"+SelectedAmount);
  },

  getPaymentScanners(SelectedAmount){
    return Service.get("/websiteSetting/getPaymentScanners/"+SelectedAmount);
    
  },

  webSlides() {
    return Service.get('/websiteSetting/getSlides');
  },

  userNotifications(portalId) {
    if(portalId){
      return Service.get('/userAccount/userNotifications/'+portalId);
    }else{
      return Service.get('/userAccount/userNotifications');
    }
  },

  userForgotOtp(data) {
    return Service.post('/userAccount/userForgetOtp',data)
  },

  userForgotPass(data){
    return Service.post('/userAccount/forgotPassword',data)
  },

  userVerifyOtpForget(mobile, otp) {
    return Service.get('/userAccount/verifyOtpForget/'+mobile+ "/"+otp);
  },

  changePassword(data) {
    return Service.put('/userAccount/changePassword?cp=aa', data);
  },

  skipPassword(){
    return Service.get('/userAccount/skipPassword');
  },

  userLogoutStatus() {
    return Service.put('/userAccount/userLogoutStatus');
  },

  userImageUpload(data) {
    return Service.put('/userAccount/uploadImage', data);
  },

  userCheckToken() {
    return Service.get('/userAccount/checkUserToken');
  },

  userBalance() {
    return Service.post('/fairExchange/playerInfo');
  },
   
  depositRequest(data) {
    return Service.put('/userAccount/depositRequest',data);
  },
  
  MasteridSubmit(signup) {
    return Service.post('/userAccount/MasteridSubmit',signup);
  },

  depositSubmit(data) {
    return Service.post('/userAccount/depositSubmit',data);
  },
  
  submitWithdrawl(data) {
    return Service.post('/userAccount/submitWithdrawl',data);
  },
  depositClientPortal(data) {
    return Service.post('/userAccount/depositClientPortal',data);
  },
  
  portalList() {
    return Service.get("/portal/PortalList");
  },

  clientPortalList() {
    return Service.get("/portal/UserClientPortal");
  },
  
  UserPortalList() {
    return Service.get("/userAccount/UserPortalList");
  },
  
  addUserAccount(data) {
    return Service.post("/userBankDetail/accountCreate", data);
  },
  
  userAccountList() {
    return Service.get("/userBankDetail/accountList");
  },

  UserPrimaryAccount() {
    return Service.get("/userBankDetail/UserPrimaryAccount");
  }
};
